<template>
  <activity_change v-loading="loading" :activity_data="activity_data" view_type="edit"></activity_change>
</template>

<script>
import activity_change from "./activity_change";
import {
  Loading
} from "element-ui";
import Vue from "vue";
Vue.use(Loading);
export default {
  name: "add_activity",
  components:{
    activity_change,
  },
  props: {
    id: {}
  },
  data() {
    return {
      activity_data: {},
      loading: true,
    };
  },
  async mounted() {
    this.loading = true;
    let _id = this.$route.query._id;
    let { data, errorCode } = await this.$http.activityDetail(_id);
    this.activity_data = data;
    this.loading = false;
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
